@font-face {
  font-family: "Cereal";
  src: local("Cereal"),
    url("./../assets/fonts/Cereal/AirbnbCereal_W_Bd.otf") format("opentype");
  font-weight: bold;
}
@font-face {
  font-family: "Cereal";
  src: local("Cereal"),
    url("./../assets/fonts/Cereal/AirbnbCereal_W_Blk.otf") format("opentype");
  font-weight: 900;
}

@font-face {
  font-family: "Cereal";
  src: local("Cereal"),
    url("./../assets/fonts/Cereal/AirbnbCereal_W_Bk.otf") format("opentype");
  font-weight: lighter;
}
@font-face {
  font-family: "Cereal";
  src: local("Cereal"),
    url("./../assets/fonts/Cereal/AirbnbCereal_W_Md.otf") format("opentype");
  font-weight: 500;
}
* {
  font-family: "Cereal";
}

body {
  margin: 0px !important;
}
a {
  text-decoration: none;
  color: inherit;
}
